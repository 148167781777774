.auth-content .ant-modal-centered .ant-modal {
    top:230px !important;
}

.btn-cbl-register.disabled-button {
    cursor: not-allowed;
    background-color: #d9d9d9 !important; /* Change to your preferred disabled color */
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none; /* This ensures hover effects are disabled */
  }
  
  .btn-cbl-register.disabled-button:hover {
    background-color: #d9d9d9 !important; /* Ensuring hover does not change the color */
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
  