.enrollment-section {
    position: relative;
    background: url('../../../assets/png/Footer.jpg') center/cover no-repeat;
    background-size: cover;
    padding: 60px 0px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    margin-top: 30px;
}

.enrollment-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.6);
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.info-box {
    width: 100%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.training-schedule {
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.9);
    margin: auto;
    padding: 50px 0;
    padding: 0 10px;
}

.enrollment-section .academy-train-title {
    margin-bottom: 0;
    font-size: 35px !important;
}

.enrollment-section .ant-typography {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px;
}

.enrollment-section .training-schedule {
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.9);
    margin: auto;
    padding: 65px 20px;
}

.training-schedule-content {
    z-index: 999;
}

.how-to-join {
    background: none !important;
    padding: 50px 0;
    max-width: 80%;
    margin: auto;
}

.how-to-join h2 {
    color: white !important;
    font-size: 35px !important;
}

.how-to-join-content {
    z-index: 999;
    color: white !important;
}

.academy-train-title,
.academy-join-title {
    font-family: gameDay-Wide;
    font-weight: 400 !important;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        align-items: center;
    }

    .enrollment-section::before {
        left: 0;
    }

    .training-schedule {
        padding: 25px 15px;
    }

    .enrollment-section .training-schedule {
        max-width: 80%;
        background-color: rgba(255, 255, 255, 0.9);
        margin: auto;
        padding: 30px 20px;
    }

    .how-to-join h2,
    .enrollment-section .academy-train-title {
        font-size: 25px !important;
    }

    .enrollment-section {
        padding: 0px;
        min-height: 300px !important;
    }

    .how-to-join {
        /* padding: 50px 0 0 0; */
    }

    .academy-training {
        margin-top: 90px;
    }
}