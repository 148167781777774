.competition-content {
    background: url('../../../assets/png/competition_footer.jpg') center/cover no-repeat;
    /* height: 100vh; */
}

.competition-content .academy-train-title {
    margin-bottom: 0;
}

.competition-content .ant-typography {
    margin-bottom: 0;
    margin-top: 15px;
}

.competition-content .training-schedule {
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.9);
    margin: auto;
    padding: 65px 20px;
}

.high-school-content {
    padding-top: 50px;
}

@media (max-width: 768px) {
    .competition-content .training-schedule {
        padding: 30px 20px;
    }

    .high-school-content {
        padding-top: 30px;
    }

    .competition-content {
        height: 90vh;
    }

    .cbl-school-regist {
        margin: 50px auto 30px auto !important;
    }
}