.page-rect {
    height: calc(100vh + 450px);
    background: #f3f3ea;
}

.partner-rect {
    top: -500px;
    margin-bottom: 0px !important;
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
}

.partner-title {
    background-color: #A6182E;
    color: white;
    text-align: center;
    padding: 20px 20px;
    border: none;
}

.partner-form-rect {
    background-color: white;
    padding: 20px;
}

.partner-item-rect {
    margin-top: 30px;
}

.partner-item-rect .title-and-details {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    border-bottom: solid 2px #e51911;
    margin: 0 0 20px 0;
}

.partner-form-rect .title-and-details .title {
    display: inline-block;
    background: #e51911;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 1.1em;
    color: #fff;
}

.partner-item-rect a {
    text-decoration: none;
    color: #000;
    font-style: normal;
    font-size: 1.05em;
    font-weight: bold;
}

.title-and-details em {
    margin: 5px 0 0 20px;
    display: inline-block;
}

.title-and-details em img {
    height: 12px;
    max-width: unset;
    max-height: unset;
    display: inline;
    margin: 0 5px 0 0;
}

.brand-logo.lazy {
    margin: 0 20px 15px 0;
    float: left;
    width: 200px;
    max-width: unset;
    max-height: unset;
    height: auto;
}

.partner-item-rect p {
    line-height: 1.3em;
    font-size: 1.1em;
}
.player-sponsors {
    background-color: white;
    display: block;
    padding: 30px 40px;
}
.player-sponsors .player {
    display: block;
    height: 250px;
    position: relative;
    margin: 0 0 55px 0;
    display: block;
    position: relative;
    background: #fff;
    border: solid 2px #f1f1f1;
}

.player-sponsors .h3,
.player-sponsors h3 {
    color: #000;
    background: #f1f1f1;
    position: absolute;
    left: 50px;
    bottom: -27px;
    font-size: 1.8em;
    padding: 10px;
    z-index: 9;
}

.player-sponsors .h3 span,
.player-sponsors h3 span {
    color: #e51911;
}

.player-sponsors .player-image {
    display: block;
    height: 280px;
    position: absolute;
    top: -33px;
    right: 50px;
    overflow: hidden;
}

.player-sponsors .brand-logo {
    max-width: 35%;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 300px;
    left: 50px;
    margin: -15px 0 0 0;
}
.player h3 {
    margin: 25px 0;
}
.player-sponsors .player-image img {
    height: 350px;
}
.player-sponsors .brand-logo {
    max-width: 300px;
    left: 50px;
}
.content-rect {
    min-height: 200px;
}
.no-found-rect {
    min-height: 400px;
}
.no-found-txt {
    margin-top: 100px;
    text-align: center;
}