.about-content {
    padding: 30px 50px;
    margin: auto;
    max-width: 1600px;
}

.logo-card {
    padding: 20px 55px;
    
}

.logo-card div {
    background-color: #E6EBF1;
    display: flex;
    justify-content: center;
}

.btn-about-more {
    padding: 8px 70px;
    height: 42px;
    background-color: var(--redColor);
    color: white;
    font-weight: bold;
}
.btn-about-more:hover {
    color: rgb(224, 222, 222) !important;
    background-color: var(--redColor) !important;
}
.btn-about-more span {
    font-family: ChakraPetch-Regular !important;
}

.align-center {
    align-items: center;
}

.btn-responsive-more {
    padding: 1px 12px !important;
    border: 1px solid var(--darkPurple);
    width: 231px;
    display: flex;
    justify-content: center;
}

.icon-about {
    /* width: 100%; */
    margin: 20px auto;
    height: 120px;
}


/* .responsive-branch {
    text-align: center;
} */
@media screen and (max-width: 1024px) {
    .icon-about {
        /* width: 180px;
        height: 120px; */
    }
}

@media screen and (max-width: 768px) {
    .about-left-content {
        justify-content: center;
    }

    .btn-responsive-more {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        width: 230px;
        margin-left: auto;
        margin-right: auto;
    }
}