.membership-item {
    width: 100%;
    min-height: 400px;
    padding: 15px;
    cursor: pointer;
    border: 1px solid #bcc5d3;
    transition: min-height 0.7s ease;
    border-radius: 8px;
    position: relative;
}

.membership-item.true {
    box-shadow: 0 0 6px #0127fa4d, 0 4px 10px #4680d45c;
    min-height: 420px;
    transition: opacity 0.5s ease, max-height 0.5s ease;
}

.current-rect {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 6px 10px;
    background-color: rgb(59 91 252);
    color: white;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-weight: 500;
}

.membership-title {
    font-size: 24px;
    text-align: center;
}

.membership-year-fee {
    text-align: center;
}

.fee-symbol {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 700;
    display: inline-block;
}

.fee-amount {
    line-height: 1.17;
    font-size: 34px;
    font-weight: 600;
    display: inline-block;
}

.fee-year {
    font-size: 15px;
    line-height: 1.4;
    display: inline-block;

}

.fee-year-rect {
    align-items: flex-end;
    display: flex;
    padding-bottom: 4px;
}

.fee-rect {
    display: flex;
}

.membership-year-fee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.membership-week-fee {
    text-align: center;
    color: #058430;
    font-weight: 700;
    margin-top: 10px;
    font-size: 16px;
}

.membership-billing {
    display: flex;
    font-size: 16px;
    margin-top: 40px;
}

.membership-billing-label {
    font-weight: 600;
    margin-right: 5px;
}

.membership-benefit {
    display: flex;
    font-size: 16px;
    margin-top: 5px;
}

.membership-benefit-label {
    font-weight: 600;
    margin-right: 5px;
}

.membership-ideal {
    display: flex;
    font-size: 16px;
    margin-top: 5px;
}

.membership-ideal-label {
    font-weight: 600;
    margin-right: 5px;
    display: block;
}

.membership-ideal-desc {
    display: block;
}

@media screen and (max-width: 768px) {
    .membership-item {
        min-height: 100%;
    }

    .membership-item.true {
        min-height: 100%;
    }
}