* {
  box-sizing: border-box;
  margin: 0;
}

.home-basketball-section {
  margin-top: 50px;
  padding-top: 100px;
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-section {
  margin-bottom: 20px;
  text-align: center;
}

.home-basketball-title {
  font-size: 50px;
  color: white;
  font-family: gameDay-Wide;
  font-weight: bold;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #d4d4d4;
}

.ant-dropdown-menu {
  top: 20px;
  padding: 5px 10px;
  background: var(--lightGreyColor) !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-family: ChakraPetch-Regular;
}

.highlight {
  color: #A7192F;
}

.home-basketball-text {
  width: 70%;
  padding: 0px 20px;
  font-size: 18px;
  font-family: ChakraPetch-Regular;
  margin: 0 auto;
  color: white;
}

.team-image-section {
  margin: 20px 0;
}

.team-image {
  width: 100%;
  max-width: 800px;
}

.icons-section {
  margin: 0;
  margin-top: 40px;
  height: 1000px;
  background-image: url('../../../assets/png/CBChome.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: white;
}

.icon-card {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 30px 50px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  backdrop-filter: blur(20px);
  border-right: 1px solid #2F2D2E;
}

.icon-card h3 {
  color: white;
  text-align: left;
  font-size: 18px;
  font-family: gameDay-Wide;
}

.icon-card p {
  color: #7B8289;
  font-size: 16px;
  text-align: left;
  white-space: normal !important;
  word-spacing: 5px;
  font-family: ChakraPetch-Regular;
}

.icon {
  float: left;
  font-size: 100px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .icon-card {
    padding: 20px 20px;
  }
}


@media screen and (max-width: 1000px) {
  .icon-card {
    padding: 20px 20px;
  }

  .home-basketball-title {
    font-size: 38px;
  }

  .icon-card p {
    line-height: 1.2;
  }
}

@media screen and (max-width: 768px) {
  .home-basketball-text {
    width: 100%;
    padding: 0 20px !important;
  }

  .icon-card p {
    color: #7B8289;
    font-size: 16px;
    text-align: left;
    white-space: normal !important;
    word-spacing: 5px;
    font-family: ChakraPetch-Regular;
  }

  .icon-card {
    padding: 20px 30px;
  }

  .home-basketball-section {
    margin-top: 30px;
  }
}