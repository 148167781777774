.home-slide {
    padding-top: 120px;
    padding-bottom: 30px;
    /* background-image:
        linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
        url('../../../assets/png/slide-back.jpg'); */
    background-image: url('../../../assets/png/imgpsh_fullsize_anim.jfif');
    height: 100vh;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    display: flex;
}

.home-little-letter {
    font-family: ChakraPetch-Regular !important;
}

.bg-slide {
    /* position: absolute; */
    bottom: 50px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.bg-left-slide {
    display: flex !important;
    align-items: end;
}

.landing-img-rect {
    text-align: center;
    /* position: relative; */
}

.landing-img-rect .landing-text-rect {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.blog-img-rect {
    border: 2px solid grey;
    width: 135px;
    height: 110px;
    margin-left: 10px;
    padding: 0 !important;
}

.blog-img-rect img {
    width: 100%;
    height: 100%;
}

.landing-img {
    width: 60%;
    height: auto;
    max-height: 500px;
}

.landing-text-rect {
    padding: 10px 30px;
}

.landing-text-rect .slider {
    width: calc(100% - 290px);
    position: relative;
}

.landing-text-rect h1 {
    line-height: 1.05;
    font-family: gameDay-Wide;
}

.landing-text-rect p {
    font-family: ChakraPetch-Regular;
}

.ant-progress-success-bg,
.ant-progress-bg {
    background-color: white !important;
}

.ant-progress-inner {
    background-color: #706F70 !important;
    height: 3px;
}

.ant-space-horizontal {
    gap: 0px !important;
}

.nav-active svg {
    margin-left: -7px !important;
}


@media screen and (max-width: 1024px) {
    .landing-text-rect {
        padding: 10px 20px;
    }

    .blog-img-rect {
        width: 115px;
        height: 90px;
    }

    .landing-text-rect .slider {
        width: calc(100% - 250px);
        position: relative;
    }

    .landing-text-rect h1 {
        font-size: 45px;
    }
}

@media screen and (max-width: 768px) {
    .landing-text-rect {
        margin-top: 10px;
        padding: 10px 20px;
    }

    .bg-left-slide {
        /* margin-bottom: -90px; */
    }
}