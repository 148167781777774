.Login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--adminMainColor);
}

.Login-loginBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  padding: 0 2rem 4rem;
  background-color: #f9f9fa;
  color: #424750;
  border-radius: 12px;
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.Login-loginBox h2 {
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 0;
  font-weight: 400;
  margin: -2rem 0 4rem;
}

.Login-logo {
  margin-top: -2rem;
  width: 200px;
}

.Login-logo img {
  width: 100%;
}

.Login_bg {
  width: 800px;
  min-width: 600px;
  order: 2;
}

.ant-form-item-control-input-content input {
  background: none !important;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  background-color: #55cefd;
}

.login-form-button:hover {
  background-color: #33c1fa !important;
}

.admin-signin-content {
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 30px;

}

.admin-signin-content h1 {
  font-family: "gameDay-Wide";

  margin-top: 50px;
  line-height: 1.5;
}

.bg-signin {
  padding: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  margin: auto;
}

.bg-signin .typo-blue {
  font-size: 40px !important;
}

.bg-signin .typo-red {
  font-size: 40px !important;
}

.bg-signin .typo-general {
  max-width: 600px;
  text-align: center;
  margin: auto;
  margin-top: 15px;
}

.auth-text {
  max-width: 500px;
  margin: auto;
}

.auth-part {
  background-color: white;
  padding: 10px;
  max-width: 400px;
}

.ant-pagination .ant-pagination-options {
  margin-top: -5px;
}

.ant-select .ant-select-arrow {
  height: 10px !important;
}

.auth-part .title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 23px;
  color: rgb(82, 81, 81) !important;
}

.icon-wrapper {
  background-color: red !important;
  padding: 5px;
  border-radius: 4px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.ant-table {
  table-layout: auto;
  width: 100%;
}

.ant-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  flex-shrink: 0;
  width: 250px;
  background-color: #f0f0f0;
}

.main-content {
  flex-grow: 1;
  overflow-y: visible;
  padding: 20px;
}

.table-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .Login-loginBox {
    width: 100%;
  }
  .auth-part {
    max-width: 100%;
  }
}