.feature-content {
    padding: 30px 50px;
    margin: auto !important;
    max-width: 1600px;
    justify-content: space-between;
}

/* .feature-content div {
    padding: 0 !important;
} */

.btn-feature {
    border: none !important;
    height: 35px !important;
    padding: 0px 80px !important;
    font-weight: bold !important;
    color: var(--whiteColor) !important;
    /* margin-left: 20px; */
}

.btn-feature:hover {
    color: rgb(224, 222, 222) !important;
}

.text-center {
    text-align: center;
}

@media screen and (max-width: 914px) {
    .feature-content {
        justify-content: center;
        padding: 30px 10px;
    }

    .feature-content p {
        margin-bottom: 9px;
    }
}