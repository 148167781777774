
.ant-modal.add-user-modal {
    top: 100px;
    width: 55% !important;
}
.ant-modal.add-user-modal .ant-modal-close {
    /* display: none; */
}
.ant-modal.add-user-modal .ant-modal-content {
    border: 4px solid var(--whiteColor);
    border-radius: 10px;
}
.ant-modal.add-user-modal .ant-modal-header {
    border-bottom: none;
}
.ant-modal.add-user-modal .ant-modal-header .ant-modal-title {
    font-size: 20px !important;
}
.ant-modal.add-user-modal .ant-modal-body {
    padding: 5px 24px 12px;
}

.ant-modal.add-user-modal .ant-modal-footer {
    border-top: none;
    padding: 5px 0px 30px 0px;
}
.add-user-button {
    width: 200px !important;
    padding: 6px 30px;
    color: white;
}
.add-user-button:hover {

}