.programs-section {
    padding: 50px !important;
    margin: auto;
    max-width: 1600px;
}

.academy-program-title {
    font-family: gameDay-Wide;

}

@media screen and (max-width: 1050px) {
    .programs-section {
        padding: 50px !important;
    }
}

@media screen and (max-width: 768px) {
    .programs-section {
        padding: 30px 20px !important;
    }

    .academy-programs {
        margin-top: 170px;
        /* position: absolute; */
    }
}