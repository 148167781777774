.slide-event-card {
    padding: 0 10px 30px 0px;
}

.slide-event-card .img-slide {
    border: 1px solid #345D7E;
}

.slide-event-card .img-slide:hover {
    border: 1px solid white;
}
.slick-dots li button:before {
    color: white !important;
    font-size: 9px !important;
}
.event-rect {
    position: relative;
    background-image:
        linear-gradient(rgba(0, 56, 101, 0.9), rgba(0, 56, 101, 0.9)),
        url('../../../assets/png/Event Banner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 70px;
    padding-bottom: 65px;
}

.event-rect .title-content {
    padding: 80px 50px 0px 50px;
    max-width: 1600px;
    margin: auto;
}

.event-slides-content {
    max-width: 1600px;
    margin: auto;
}

.event-title {
    line-height: 1.3;
}

.event-rect p {
    line-height: 1.4;
}

.btn-limited-spots {
    color: #fa8ca2;
    background: none;
    border: 1px solid #e41c44;
}

.btn-limited-spots span {
    font-family: ChakraPetch-Regular !important;
}

.btn-limited-spots:hover {
    color: #fa6180 !important ;
    background: none !important;
    border: 1px solid #e90325 !important;
}

.btn-limited-tour {
    color: #7FAFD4;
    background: none;
    border: 1px solid #7FAFD4;
}

.btn-limited-tour span {
    font-family: ChakraPetch-Regular !important;
}

.btn-limited-tour:hover {
    color: white;
    background: none !important;
    border: 1px solid white;
}

@media screen and (max-width: 914px) {
    .event-slides-content {
        /* padding: 0 20px; */
        margin-top: 60px !important;
    }
}