.customer-rect {
    padding: 40px 50px;
    margin: auto;
    max-width: 1600px;
}

.branch-content {
    border: 2px solid rgb(218, 216, 216);
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 14px;
    width: 400px;
    height: 260px;
}
.branch-about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.branch-content:hover {
    border: 2px solid rgb(216, 4, 4);
}
.img-container {
    width: 330px; /* or whatever width you prefer */
    height: 210px; /* or adjust as necessary */
    overflow: hidden; /* Hide overflow if image exceeds container */
    display: flex; /* Center the image */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .img-container img{
    width: 280px;
  }

  .image-wrapper {
    position: relative; /* Required for hover effects */
  }
  
.image-wrapper {
    width: 100%;
    position: relative;
    /* display: inline-block; */
    overflow: hidden; 

    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.branch-img {
    width: 100%;
    height: 230px;
    display: block;
    transition: opacity 0.6s ease;
}

.title-content {
    padding: 80px 50px 0px 50px;
}

.image-wrapper:hover {
    /* background-color: rgb(204, 3, 3);
    opacity: 0.9; */
}

.image-wrapper:hover .branch-img {
    opacity: 0.3;
}

.hover-image {
    position: absolute;
    top: 50%; 
    left: 50%; 
    width: 230px;
    height: 170px;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center; 
    transform: translate(-50%, -50%); 
    opacity: 0; 
    transition: opacity 0.3s ease; 
    z-index: 1; 
}

.hover-image.visible {
    opacity: 1; /* Show the image on hover */
}

@media screen and (max-width: 968px) {
    .branch-content {
        max-width: 325px;
    }
}

@media screen and (max-width: 768px) {
    .branch-about-content {
        justify-content: center;
    }

    .customer-rect {
        padding: 30px 20px;
    }

    .branch-content {
        max-width: 325px;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 7px;
        width: 325px;
    }

    .responsive-row {
        justify-content: center !important;
    }

    .img-container img{
        width: 200px;
      }
}

@media screen and (max-width: 692px) {
    .branch-img {
        height: auto !important;
    }

    .event-rect .title-content {
        padding: 50px 30px 0px 30px !important;
    }
}
